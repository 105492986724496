import { InlineContentType } from '@wearemojo/sanity-schema';
import { Spacing, themeColors, UITheme } from '@wearemojo/ui-constants';
import { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import Stack from './Stack';
import Text from './Text';

type Props = {
	label: InlineContentType;
	price: InlineContentType;
	originalPrice?: InlineContentType;
};

const CheckoutPriceBlock = ({ label, originalPrice, price }: Props) => {
	const labelContent = useMemo(
		() => <Text.CMSContent value={label} />,
		[label],
	);
	const priceContent = useMemo(
		() => <Text.CMSContent value={price} />,
		[price],
	);
	const originalPriceContent = useMemo(
		() => originalPrice && <Text.CMSContent value={originalPrice} />,
		[originalPrice],
	);

	return (
		<View style={styles.container}>
			<Stack direction="row" itemFlex={[true, false]}>
				<Text>{labelContent}</Text>
				<Stack direction="row" spacing={Spacing.extraSmall}>
					<Text themeColor="content_neutral" weight="bold">
						{originalPriceContent}
					</Text>
					<Text weight="bold">{priceContent}</Text>
				</Stack>
			</Stack>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		borderTopColor: themeColors[UITheme.dark].border_neutral,
		borderTopWidth: 1,
		borderBottomColor: themeColors[UITheme.dark].border_neutral,
		borderBottomWidth: 1,
		borderStyle: 'solid',
		paddingVertical: Spacing.large,
	},
});

export default CheckoutPriceBlock;
