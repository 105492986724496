import { Spacing } from '@wearemojo/ui-constants';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';

import SplitControl from './SplitControl';
import Stack from './Stack';

export type Props = {
	choices: ReturnType<typeof useContentSwitcher>['selectedChoices'];
	onOptionPress: (option: { choiceKey: string; optionKey: string }) => void;
	children: ReactNode;
};

type Choice = {
	key: string;
	title: string;
	columns?: number;
	options: {
		key: string;
		name: string;
	}[];
	selectedOptionKey?: string;
};

type OptionPress = {
	choiceKey: string;
	optionKey: string;
};

/**
 * `useContentSwitcher` is a custom hook that manages the state of the content switcher component.
 * It takes an array of selection options and dynamic content as parameters.
 *
 * @param {Choice[]} initialChoices - The options for the content switcher.
 * @param {DynamicContent[]} dynamicContent - The dynamic content that corresponds to each option.
 *
 * @returns {Object} The selected options, a function to handle option press, and the content corresponding to the selected option.
 */
export const useContentSwitcher = <T extends { key: string }>(
	initialChoices: Choice[],
	dynamicContent: T[],
) => {
	const preparedChoices = initialChoices.map((choice) => {
		const firstOption = choice.options[0];
		if (!firstOption) {
			throw new Error('ContentSwitcher choice must have at least one option');
		}
		return {
			...choice,
			selectedOptionKey: choice.selectedOptionKey || firstOption?.key,
		};
	});

	const [selectedChoices, setSelectedChoices] = useState(preparedChoices);

	const SEPARATOR = '/';

	const onOptionPress = useCallback(
		({ choiceKey, optionKey }: OptionPress) => {
			const newChoices = selectedChoices.map((choice) => {
				if (choice.key === choiceKey) {
					return { ...choice, selectedOptionKey: optionKey };
				}
				return choice;
			});
			setSelectedChoices(newChoices);
		},
		[selectedChoices],
	);

	const dynamicKey = useMemo(
		() =>
			selectedChoices.map((choice) => choice.selectedOptionKey).join(SEPARATOR),
		[selectedChoices],
	);

	const content = useMemo(
		() => dynamicContent.find(({ key }) => dynamicKey === key),
		[dynamicContent, dynamicKey],
	);

	const setChoices = (choices: Choice[]) => {
		const formattedChoices = choices.map((choice) => {
			const firstOption = choice.options[0];
			if (!firstOption) {
				throw new Error('ContentSwitcher choice must have at least one option');
			}
			return {
				...choice,
				selectedOptionKey: choice.selectedOptionKey || firstOption?.key,
			};
		});
		setSelectedChoices(formattedChoices);
	};

	return { selectedChoices, onOptionPress, content, setChoices };
};

const ContentSwitcher = ({ choices, onOptionPress, children }: Props) => {
	const [viewHeight, setViewHeight] = useState(0);

	const splitControlItems = choices.map((choice) => (
		<SplitControl
			key={choice.key}
			optionColumns={choice.columns}
			optionsTitle={choice.title}
			optionsList={choice.options}
			selectedOptionKey={choice.selectedOptionKey}
			onOptionPress={(option) =>
				onOptionPress({ choiceKey: choice.key, optionKey: option.key })
			}
		/>
	));

	return (
		<Stack spacing={Spacing.regular}>
			<View style={{ minHeight: viewHeight }}>
				<View
					onLayout={({ nativeEvent }) => {
						setViewHeight(nativeEvent.layout.height);
					}}
				>
					{children}
				</View>
			</View>
			{splitControlItems}
		</Stack>
	);
};

export default ContentSwitcher;
