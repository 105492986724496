import { Spacing } from '@wearemojo/ui-constants';
import { ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import ChecklistIcon from './ChecklistIcon';
import Stack from './Stack';
import Text from './Text';

type Props = {
	children: ReactNode;
};

type ItemProps = {
	checked?: boolean;
	children: ReactNode;
};

export const Checklist = ({ children }: Props) => (
	<Stack spacing={Spacing.large}>{children}</Stack>
);

export const ChecklistItem = ({ checked, children }: ItemProps) => (
	<Stack direction="row" spacing={Spacing.large} itemFlex={[false, true]}>
		<ChecklistIcon style={styles.icon} checked={checked} />
		<Text>{children}</Text>
	</Stack>
);

const styles = StyleSheet.create({
	icon: {
		marginTop: 2,
	},
});

export default Checklist;
