import { useEffect, useMemo, useRef, useState } from 'react';
import { Image, Platform, StyleSheet, View, ViewStyle } from 'react-native';
import Animated, {
	Easing,
	useAnimatedStyle,
	useSharedValue,
	withRepeat,
	withTiming,
} from 'react-native-reanimated';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import { normalizeValue, resample, WaveformData } from './utils/waveform';

const mask = require('./assets/audio-viz-mask.png');
const shape = require('./assets/audio-viz-shape.png');

type Props = {
	waveformData: WaveformData;
	durationMs: number;
	positionMsRef: React.MutableRefObject<number>;
	isPlaying: boolean;
	color?: string;
};

const MAX_SIZE = 640;
const UPDATES_PER_SEC = 30;
const SCALE_DECAY = 0.95;

const OPACITY_IDLE = 0.3;
const OPACITY_MAX = 0.8;

const SCALE_MAX = 1.5;

const timingConfig = (enter?: boolean) => ({
	duration: enter ? 700 : 350,
	easing: enter ? Easing.ease : Easing.inOut(Easing.quad),
});

const rotateTimingConfig = (size: number, isPlaying?: boolean) => ({
	duration: size * (isPlaying ? 100 : 200),
	easing: Easing.ease,
});

const AudioViz = ({
	waveformData,
	durationMs,
	positionMsRef,
	isPlaying,
	color,
}: Props) => {
	const { styles, theme } = useStyles(stylesheet);
	const [isMaskLoaded, setIsMaskLoaded] = useState(false);
	const [size, setSize] = useState(300);

	const [dataMin, dataMax] = useMemo(() => {
		const is16Bit = waveformData.bits === 16;
		const bitMin = is16Bit ? -32768 : -127;
		const bitMax = is16Bit ? 32767 : 128;
		const actualMin = waveformData.data.reduce(
			(min, val) => Math.min(min, val),
			0,
		);
		const actualMax = waveformData.data.reduce(
			(max, val) => Math.max(max, val),
			0,
		);
		return [Math.max(actualMin, bitMin), Math.min(actualMax, bitMax)];
	}, [waveformData.bits, waveformData.data]);

	const durationSecs = durationMs / 1000;
	const targetPoints =
		Math.round(durationSecs * UPDATES_PER_SEC * waveformData.channels) * 2;
	const resampled = useMemo(
		() => resample(waveformData.data, targetPoints, waveformData.channels),
		[waveformData.data, waveformData.channels, targetPoints],
	);

	return (
		<View
			style={styles.root}
			onLayout={({ nativeEvent: { layout } }) => {
				setSize(Math.min(MAX_SIZE, layout.width, layout.height));
			}}
		>
			<View style={[styles.container, { width: size }]}>
				<View style={styles.pin}>
					<Amplitude
						data={resampled}
						dataMin={dataMin}
						dataMax={dataMax}
						durationMs={durationMs}
						positionMsRef={positionMsRef}
						isPlaying={isPlaying}
						color={color ?? theme.colors.fill_highlight}
						size={size}
						hide={!isMaskLoaded}
					/>
					<View style={styles.pin}>
						<Animated.Image
							source={mask}
							style={styles.image}
							onLoad={() => setIsMaskLoaded(true)}
						/>
					</View>
				</View>
			</View>
		</View>
	);
};

const Amplitude = ({
	data,
	durationMs,
	positionMsRef,
	isPlaying,
	dataMin,
	dataMax,
	color,
	size = 300,
	hide = false,
}: Omit<Props, 'waveformData'> & {
	data: number[];
	dataMin: number;
	dataMax: number;
	color: string;
	size?: number;
	hide?: boolean;
}) => {
	const scale = useSharedValue(1);
	const rotate = useSharedValue(0);
	const opacity = useSharedValue(0);

	const prevScale = useRef(0);
	useEffect(() => {
		if (!positionMsRef || !isPlaying) return;
		let prevUpdate = Date.now();
		let frame: number;

		function update() {
			const now = Date.now();
			const elapsed = now - prevUpdate;

			if (elapsed < 1000 / UPDATES_PER_SEC) {
				frame = requestAnimationFrame(update);
				return;
			}

			const perc = positionMsRef.current / durationMs;
			const sampleMaxIndex = Math.floor(perc * (data.length / 2)) * 2 - 1; // max as in always the even index, i.e. [min0, max0, min1, max1, ...]
			const sample = data[sampleMaxIndex] ?? 0;
			const constrainedSample = Math.max(
				normalizeValue(sample, dataMin, dataMax, 0, SCALE_MAX),
				0,
			);

			const scaleDecay =
				prevScale.current > constrainedSample
					? prevScale.current * SCALE_DECAY
					: constrainedSample;
			scale.value = scaleDecay;
			prevScale.current = scaleDecay;

			frame = requestAnimationFrame(update);
			prevUpdate = now;
		}

		frame = requestAnimationFrame(update);
		return () => cancelAnimationFrame(frame);
	}, [data, dataMax, dataMin, durationMs, isPlaying, positionMsRef, scale]);

	useEffect(() => {
		if (Platform.OS === 'android') {
			// Give low power devices one less thing to worry about
			return;
		}

		rotate.value = withRepeat(
			withTiming(360, rotateTimingConfig(size, isPlaying)),
			-1,
			true,
		);
	}, [rotate, size, isPlaying]);

	useEffect(() => {
		if (hide) {
			opacity.value = withTiming(0, timingConfig(false));
			return;
		}

		opacity.value = withTiming(
			isPlaying ? OPACITY_MAX : OPACITY_IDLE,
			timingConfig(isPlaying),
		);
	}, [opacity, isPlaying, hide]);

	const animatedStyle = useAnimatedStyle(() => ({
		transform: [{ scale: scale.value }, { rotateZ: `${rotate.value}deg` }],
		opacity: opacity.value,
	}));

	const { styles } = useStyles(stylesheet);
	const style: ViewStyle = useMemo(
		() => ({
			position: 'relative',
			width: size,
			height: size,
			backgroundColor: color,
		}),
		[size, color],
	);

	return (
		<Animated.View style={[style, animatedStyle]}>
			<Image source={shape} style={styles.image} resizeMode="cover" />
		</Animated.View>
	);
};

const AudioVizStatic = ({
	color,
	isPlaying,
}: {
	color?: string;
	isPlaying?: boolean;
}) => {
	const { styles, theme } = useStyles(stylesheet);
	const [isMaskLoaded, setIsMaskLoaded] = useState(false);
	const [size, setSize] = useState(300);

	const scale = useSharedValue(1);
	const rotate = useSharedValue(0);
	const opacity = useSharedValue(0);

	useEffect(() => {
		rotate.value = withRepeat(
			withTiming(360, rotateTimingConfig(size, isPlaying)),
			-1,
			true,
		);
	}, [rotate, size, isPlaying]);

	useEffect(() => {
		const value = isMaskLoaded ? (isPlaying ? OPACITY_MAX : OPACITY_IDLE) : 0;
		opacity.value = withTiming(value, timingConfig(isPlaying));
		scale.value = withTiming(isPlaying ? 1.3 : 0.9, timingConfig(isPlaying));
	}, [opacity, isPlaying, isMaskLoaded, scale]);

	const style: ViewStyle = useMemo(
		() => ({
			position: 'relative',
			width: size,
			height: size,
			backgroundColor: color ?? theme.colors.fill_highlight,
		}),
		[size, color, theme.colors.fill_highlight],
	);

	const animatedStyle = useAnimatedStyle(() => {
		return {
			opacity: opacity.value,
			transform: [{ scale: scale.value }, { rotateZ: `${rotate.value}deg` }],
		};
	});

	return (
		<View
			style={styles.root}
			onLayout={({ nativeEvent: { layout } }) => {
				setSize(Math.min(MAX_SIZE, layout.width, layout.height));
			}}
		>
			<View style={[styles.container, { width: size }]}>
				<View style={styles.pin}>
					<Animated.View style={[style, animatedStyle]}>
						<Image source={shape} style={styles.image} resizeMode="cover" />
					</Animated.View>
					<View style={styles.pin}>
						<Animated.Image
							source={mask}
							style={styles.image}
							onLoad={() => setIsMaskLoaded(true)}
						/>
					</View>
				</View>
			</View>
		</View>
	);
};

AudioViz.Static = AudioVizStatic;

const stylesheet = createStyleSheet({
	root: {
		width: '100%',
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	container: {
		width: 300,
		aspectRatio: 1,
		overflow: 'hidden',
	},
	pin: {
		...StyleSheet.absoluteFillObject,
	},
	image: {
		/*
			Android shows tearing along the edge (color bleeds through) of the image
			when animating transforms, adding a tiny bit of "overscan" combats this
		*/
		marginTop: '-1%',
		marginLeft: '-1%',
		width: '102%',
		height: '102%',
	},
});

export default AudioViz;
