import { Spacing } from '@wearemojo/ui-constants';
import { ReactNode } from 'react';
import { StyleSheet } from 'react-native';

import BackgroundView from './BackgroundView';
import Stack from './Stack';
import Text from './Text';

type Props = {
	children: ReactNode;
};

const Blockquote = ({ children }: Props) => {
	return (
		<Stack direction="row" itemFlex={[false, true]} spacing={Spacing.large}>
			<BackgroundView background="fill_highlight" style={styles.line} />
			<Text>{children}</Text>
		</Stack>
	);
};

const styles = StyleSheet.create({
	line: {
		flex: 1,
		width: 10,
		borderRadius: 10,
	},
});

export default Blockquote;
