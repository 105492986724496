import { Spacing } from '@wearemojo/ui-constants';
import { ReactNode } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

const Block = ({
	children,
	spacing = undefined,
	style = {},
	direction = 'column',
}: {
	children: ReactNode;
	spacing?: Spacing;
	style?: StyleProp<ViewStyle>;
	direction?: 'column' | 'row';
}) => (
	<View
		style={[
			styles.block,
			spacing && styles[blockSpacingKey(spacing, direction)],
			style,
		]}
	>
		{children}
	</View>
);

type Direction = 'column' | 'row';

const blockSpacingKey = (
	spacing: Spacing,
	direction: Direction = 'column',
): `blockSpacing__${Direction}__${Spacing}` =>
	`blockSpacing__${direction}__${spacing}`;
const blockSpacingStyles = (Object.values(Spacing) as Array<Spacing>).reduce(
	(styles, spacing) => {
		styles[blockSpacingKey(spacing, 'column')] = {
			marginBottom: spacing,
		};
		styles[blockSpacingKey(spacing, 'row')] = {
			marginRight: spacing,
		};
		return styles;
	},
	{} as Record<`blockSpacing__${Direction}__${Spacing}`, ViewStyle>,
);

const styles = StyleSheet.create({
	block: {
		alignSelf: 'stretch',
	},
	...blockSpacingStyles,
});

export default Block;
