import { StyleProp, TextStyle, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Checkbox, { Props as CheckboxProps } from './Checkbox';
import Text, { TextPropStyles } from './Text';

type Props = {
	label: string;
	textProps?: Pick<TextPropStyles, 'variant' | 'weight'>;
	textStyle?: StyleProp<TextStyle>;
} & CheckboxProps;

const CheckboxWithLabel = ({
	label,
	checked,
	disabled,
	onChange,
	textProps,
	textStyle,
	...checkboxProps
}: Props) => {
	const { styles } = useStyles(stylesheet);

	return (
		<View style={styles.root}>
			<Checkbox
				checked={checked}
				onChange={onChange}
				disabled={disabled}
				{...checkboxProps}
			/>
			<Text
				onPress={() => onChange?.(!checked)}
				style={[disabled && styles.disabledLabel, textStyle]}
				{...textProps}
			>
				{label}
			</Text>
		</View>
	);
};

const stylesheet = createStyleSheet(({ spacing }) => ({
	root: {
		flexDirection: 'row',
		alignItems: 'center',
		gap: spacing.xs,
	},
	disabledLabel: {
		opacity: 0.5,
	},
}));

export default CheckboxWithLabel;
