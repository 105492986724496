import { BlockContentType } from '@wearemojo/sanity-schema';
import { Spacing } from '@wearemojo/ui-constants';

import Button from './Button';
import CMSContent from './CMSContent';
import PopupInfo from './PopupInfo';
import Stack from './Stack';

type Props = {
	communityExplained: {
		content: BlockContentType;
		ctaText: string;
	};
	nextStep: () => void;
};

const CommunityOnboardingModal = ({ communityExplained, nextStep }: Props) => {
	return (
		<PopupInfo>
			<Stack spacing={Spacing.extraLarge}>
				<CMSContent value={communityExplained.content} />
				<Button onPress={nextStep} title={communityExplained.ctaText} />
			</Stack>
		</PopupInfo>
	);
};

export default CommunityOnboardingModal;
