import { themeColors, UIThemeColor } from '@wearemojo/ui-constants';
import { ReactNode, useMemo } from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';

import useUIContext from './hooks/useUIContext';

export type Props = {
	children?: ReactNode;
	background: Extract<UIThemeColor, `background_${string}` | `fill_${string}`>;
} & ViewProps;

const BackgroundView = ({
	background,
	children,
	style,
	...viewProps
}: Props) => {
	const { theme } = useUIContext();

	const viewStyle = useMemo(
		() =>
			StyleSheet.flatten([
				{
					backgroundColor: themeColors[theme][background],
				},
				style,
			]),
		[background, style, theme],
	);

	return (
		<View style={viewStyle} {...viewProps}>
			{children}
		</View>
	);
};

export default BackgroundView;
