import { PortableText, PortableTextComponents } from '@portabletext/react';
import { SanityDocument } from '@sanity/client';
import { Spacing } from '@wearemojo/ui-constants';
import { useContext, useMemo } from 'react';
import { Linking, StyleSheet, Text as RNText, View } from 'react-native';

import Blockquote from './Blockquote';
import CMSAccordion from './cms/CMSAccordion';
import CMSAnimation from './cms/CMSAnimation';
import CMSAudio from './cms/CMSAudio';
import CMSBillingBlock from './cms/CMSBillingBlock';
import CMSBlock from './cms/CMSBlock';
import CMSCarousel from './cms/CMSCarousel';
import CMSChecklist from './cms/CMSChecklist';
import CMSCheckoutPriceBlock from './cms/CMSCheckoutPriceBlock';
import CMSConceptBlock from './cms/CMSConceptBlock';
import CMSContentCardBlock from './cms/CMSContentCardBlock';
import CMSContentCardList from './cms/CMSContentCardList';
import CMSContentSwitcher from './cms/CMSContentSwitcher';
import CMSContentVariable from './cms/CMSContentVariable';
import CMSContext from './cms/CMSContext';
import CMSEnableNotificationsButton from './cms/CMSEnableNotificationsButton';
import CMSExpertChip from './cms/CMSExpertChip';
import CMSExpertQuote from './cms/CMSExpertQuote';
import CMSFlair from './cms/CMSFlair';
import CMSHr from './cms/CMSHr';
import CMSImage from './cms/CMSImage';
import CMSInfoCardBlock from './cms/CMSInfoCardBlock';
import CMSMemorableBlock from './cms/CMSMemorableBlock';
import CMSNumberedList from './cms/CMSNumberedList';
import CMSProgressTrail from './cms/CMSProgressTrail';
import CMSQuoteCardBlock from './cms/CMSQuoteCardBlock';
import CMSReference from './cms/CMSReference';
import CMSResearchCarousel from './cms/CMSResearchCarousel';
import CMSScienceBlock from './cms/CMSScienceBlock';
import CMSTileWithExplanationList from './cms/CMSTileWithExplanationList';
import CMSTrackingButton from './cms/CMSTrackingButton';
import CMSVideo from './cms/CMSVideo';
import CMSVideoKeyPoints from './cms/CMSVideoKeyPoints';
import CMSWarningBlock from './cms/CMSWarningBlock';
import ErrorBox from './ErrorBox';
import { List, ListItem } from './List';
import Text, { TextPropStyles } from './Text';
import { withErrorBoundary } from './utils/errors';
import { BlockContent, ContentVariables, TextVariantish } from './utils/types';
import { monospaceFont } from './utils/typography';

const DEFAULT_START_HEADING_LEVEL = 3;
export const CMS_IMAGE_WIDTH = 800;

type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

type Props = {
	value?: BlockContent;
	spacing?: Spacing;
	textAlign?: TextPropStyles['align'];
	pauseAudioOnUnmount?: boolean;
	contentVariables?: ContentVariables;
} & PortableTextConfig;

type PortableTextConfig = {
	startHeadingLevel?: HeadingLevel;
	normalTextVariant?: TextVariantish;
	onPressLink?: (href: string) => void;
};

const CMSContent = ({
	value,
	spacing = Spacing.large,
	textAlign = 'auto',
	pauseAudioOnUnmount = false,
	contentVariables: contentVariablesProp,
	...componentsConfig
}: Props) => {
	const components = useCMSContentComponents({
		...componentsConfig,
	});

	const existingContext = useContext(CMSContext);
	const contentVariables =
		contentVariablesProp ?? existingContext.contentVariables;

	const context = useMemo(
		() => ({
			spacing,
			textAlign,
			pauseAudioOnUnmount,
			contentVariables,
		}),
		[spacing, textAlign, pauseAudioOnUnmount, contentVariables],
	);

	const rootStyle = useMemo(
		() => ({
			// Block elements include a bottom margin, offset the last one
			marginBottom: -spacing,
		}),
		[spacing],
	);

	return (
		<CMSContext.Provider value={context}>
			<View style={rootStyle}>
				<PortableText
					value={!value ? [] : Array.isArray(value) ? value : value.content}
					components={components}
					listNestingMode="direct"
				/>
			</View>
		</CMSContext.Provider>
	);
};

const headingLevelStyles: Record<number, TextPropStyles> = {
	1: { variant: 'heading_xl' },
	2: { variant: 'heading_lg' },
	3: { variant: 'heading_md' },
	4: { variant: 'heading_sm' },
	5: { variant: 'heading_xs' },
};
const totalHeadingLevels = Object.keys(headingLevelStyles).length;

const getHeadingLevelStyle = (level: number, start: number = 1) => {
	const targetLevel = Math.max(
		Math.min(level + Number(start) - 1, totalHeadingLevels),
		1,
	);
	return headingLevelStyles[targetLevel];
};

const DEFAULT_ON_PRESS_LINK = (href: string) => {
	// TODO: handle internal links
	// TODO: move to "proper" href-including <a> links on web
	Linking.openURL(href);
};

const useCMSContentComponents = ({
	startHeadingLevel = DEFAULT_START_HEADING_LEVEL,
	normalTextVariant = 'body_lg',
	onPressLink = DEFAULT_ON_PRESS_LINK,
}: PortableTextConfig): PortableTextComponents =>
	useMemo(() => {
		const headingLevel1 = getHeadingLevelStyle(1, startHeadingLevel);
		const headingLevel2 = getHeadingLevelStyle(2, startHeadingLevel);
		const headingLevel3 = getHeadingLevelStyle(3, startHeadingLevel);
		const headingLevel4 = getHeadingLevelStyle(4, startHeadingLevel);
		const headingLevel5 = getHeadingLevelStyle(5, startHeadingLevel);
		const headingLevel6 = getHeadingLevelStyle(6, startHeadingLevel);

		return {
			types: {
				image: withErrorBoundary(CMSImage),
				Video: withErrorBoundary(CMSVideo),
				Audio: withErrorBoundary(CMSAudio),
				Animation: withErrorBoundary(CMSAnimation),
				Accordion: withErrorBoundary(CMSAccordion),
				BillingBlock: withErrorBoundary(CMSBillingBlock),
				Carousel: withErrorBoundary(CMSCarousel),
				ResearchCarousel: withErrorBoundary(CMSResearchCarousel),
				Checklist: withErrorBoundary(CMSChecklist),
				CheckoutPriceBlock: withErrorBoundary(CMSCheckoutPriceBlock),
				ConceptBlock: withErrorBoundary(CMSConceptBlock),
				ContentCardBlock: withErrorBoundary(CMSContentCardBlock),
				ContentCardList: withErrorBoundary(CMSContentCardList),
				ContentSwitcherBlock: withErrorBoundary(CMSContentSwitcher),
				EnableNotificationsButton: withErrorBoundary(
					CMSEnableNotificationsButton,
				),
				ExpertChip: withErrorBoundary(CMSExpertChip),
				ExpertQuote: withErrorBoundary(CMSExpertQuote),
				Flair: withErrorBoundary(CMSFlair),
				Hr: withErrorBoundary(CMSHr),
				MemorableBlock: withErrorBoundary(CMSMemorableBlock),
				InfoCardBlock: withErrorBoundary(CMSInfoCardBlock),
				NumberedList: withErrorBoundary(CMSNumberedList),
				ProgressTrail: withErrorBoundary(CMSProgressTrail),
				QuoteCardBlock: withErrorBoundary(CMSQuoteCardBlock),
				ScienceBlock: withErrorBoundary(CMSScienceBlock),
				TrackingButton: withErrorBoundary(CMSTrackingButton),
				TileWithExplanationList: withErrorBoundary(CMSTileWithExplanationList),
				VideoKeyPoints: withErrorBoundary(CMSVideoKeyPoints),
				WarningBlock: withErrorBoundary(CMSWarningBlock),
				reference: withErrorBoundary(CMSReference),
			},
			marks: {
				em: ({ children }) => <Text italic={true}>{children}</Text>,
				strong: ({ children }) => <Text weight="bold">{children}</Text>,
				code: ({ children }) => (
					<Text style={styles.monospace}>{children}</Text>
				),
				underline: ({ children }) => <Text underline={true}>{children}</Text>,
				'strike-through': ({ children }) => (
					<Text strikethrough={true}>{children}</Text>
				),
				link: ({ children, value }) => (
					<Text underline={true} onPress={() => onPressLink(value?.href)}>
						{children}
					</Text>
				),
				contentVariable: ({ text: path, children }) => {
					return (
						<CMSContentVariable path={path}>{children}</CMSContentVariable>
					);
				},
			},
			block: {
				normal: ({ children }) => (
					<CMSBlock>
						<Text variant={normalTextVariant}>{children}</Text>
					</CMSBlock>
				),
				blockquote: ({ children }) => (
					<CMSBlock textAlign="auto">
						<Blockquote>{children}</Blockquote>
					</CMSBlock>
				),
				h1: ({ children }) => (
					<CMSBlock>
						<Text {...headingLevel1}>{children}</Text>
					</CMSBlock>
				),
				h2: ({ children }) => (
					<CMSBlock>
						<Text {...headingLevel2}>{children}</Text>
					</CMSBlock>
				),
				h3: ({ children }) => (
					<CMSBlock>
						<Text {...headingLevel3}>{children}</Text>
					</CMSBlock>
				),
				h4: ({ children }) => (
					<CMSBlock>
						<Text {...headingLevel4}>{children}</Text>
					</CMSBlock>
				),
				h5: ({ children }) => (
					<CMSBlock>
						<Text {...headingLevel5}>{children}</Text>
					</CMSBlock>
				),
				h6: ({ children }) => (
					<CMSBlock>
						<Text {...headingLevel6}>{children}</Text>
					</CMSBlock>
				),
			},
			list: {
				number: ({ children }) => (
					<CMSBlock textAlign="auto">
						<List type="number">{children}</List>
					</CMSBlock>
				),
				bullet: ({ children }) => (
					<CMSBlock textAlign="auto">
						<List>{children}</List>
					</CMSBlock>
				),
			},
			listItem: ({ children }) => (
				<ListItem variant={normalTextVariant}>{children}</ListItem>
			),
			hardBreak: () => <RNText>{'\n'}</RNText>, // aka <br />
			unknownMark: ({ children }) => <Text>{children}</Text>,
			unknownType: withErrorBoundary(({ value }) => (
				<UnknownType document={value as SanityDocument} />
			)),
			unknownBlockStyle: ({ children }) => (
				<CMSBlock textAlign="auto">
					<Text>{children}</Text>
				</CMSBlock>
			),
			unknownList: ({ children }) => (
				<CMSBlock textAlign="auto">
					<List>{children}</List>
				</CMSBlock>
			),
			unknownListItem: ({ children }) => (
				<ListItem variant={normalTextVariant}>{children}</ListItem>
			),
		};
	}, [startHeadingLevel, normalTextVariant, onPressLink]);

const UnknownType = ({ document }: { document: SanityDocument }) => {
	const label = `Missing Component / Unknown Type [${document._type}]`;
	const message = JSON.stringify(document);
	console.warn(label, message);
	return (
		<CMSBlock textAlign="auto">
			<ErrorBox level="warning" label={label} message={message} />
		</CMSBlock>
	);
};

const styles = StyleSheet.create({
	monospace: {
		fontFamily: monospaceFont,
	},
	blockError: {
		backgroundColor: 'pink',
		padding: Spacing.regular,
	},
});

export default withErrorBoundary(CMSContent);
