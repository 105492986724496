import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import useUITheme from './hooks/useUITheme';
import TickIcon from './icons/TickIcon';

type Props = {
	checked?: boolean;
	style?: StyleProp<ViewStyle>;
};

export const ChecklistIcon = ({ checked, style }: Props) => {
	const fill = useUITheme().content_on_fill;
	const backgroundColor =
		useUITheme()[checked ? 'fill_highlight' : 'border_neutral'];
	return (
		<View style={[styles.root, { backgroundColor }, style]}>
			{checked && <TickIcon fill={fill} />}
		</View>
	);
};

ChecklistIcon.Size = 22;

const styles = StyleSheet.create({
	root: {
		width: ChecklistIcon.Size,
		height: ChecklistIcon.Size,
		padding: 5.5,
		borderRadius: 9999,
		justifyContent: 'center',
	},
});

export default ChecklistIcon;
