import CheckIcon from 'react-native-heroicons/outline/CheckIcon';

import Button from './Button';
import { useApiState } from './utils/apiState';

type Props = {
	type: 'primary' | 'secondary' | 'warning';
	title: string;
	titleEnabled: string;
};

const EnableNotificationsButton = ({ type, title, titleEnabled }: Props) => {
	const { state, request } = useApiState().notificationPermission ?? {};
	const { granted } = state ?? {};

	return (
		<Button
			type={type}
			icon={granted ? CheckIcon : undefined}
			title={granted ? titleEnabled : title}
			disabled={granted}
			onPress={request}
		/>
	);
};

export default EnableNotificationsButton;
