import { BrandColor, Spacing } from '@wearemojo/ui-constants';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Platform, Pressable, View } from 'react-native';
import { Slider } from 'react-native-elements';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import AudioPlayerInline from './AudioPlayerInline';
import AudioPlayerView from './AudioPlayerView';
import useUITheme from './hooks/useUITheme';
import PauseIcon from './icons/PauseIcon';
import PlayIcon from './icons/PlayIcon';
import PressableOpacity from './PressableOpacity';
import { AudioPlayerInstance, PlaybackRateType } from './utils/audio';
import { CaptureError } from './utils/errors';
import {
	AudioInteractionEvent,
	InteractionEvent,
	useInteractionEvents,
} from './utils/interactionEvents';
import { ScreenEvent, useScreenEvent } from './utils/screenEvents';
import { useLoadAudioWaveformData } from './utils/waveform';

type Props = {
	audioPlayer: AudioPlayerInstance;
	eventFields?: AudioInteractionEvent;
	showWaveform?: boolean;
	variant: 'inline' | 'standalone';
	audioImage?: {
		uri: string | undefined;
	};
	audioWaveformUri?: string;
	waveformColor?: string;
	savedPlaybackSpeed?: PlaybackRateType;
	setPlaybackSpeed?: (speed: PlaybackRateType) => void;
	captureError?: CaptureError;
};

const POSITION_REF_UPDATE_INTERVAL_MS = 1000 / 60;

const AudioPlayerContainer = ({
	audioPlayer,
	eventFields,
	showWaveform = true,
	variant = 'inline',
	audioImage,
	audioWaveformUri,
	waveformColor,
	savedPlaybackSpeed,
	setPlaybackSpeed,
	captureError,
}: Props) => {
	const theme = useUITheme();
	const { dispatch: dispatchIE } = useInteractionEvents();
	const { styles } = useStyles(stylesheet, { variant });

	const {
		play,
		pause,
		setProgress,
		jumpPositionBy,
		jumpPositionTo,
		getProgress,
		hooks: { useStatus, useProgress },
	} = audioPlayer;
	useScreenEvent(ScreenEvent.navigateOut, pause);

	const status = useStatus();
	const { error, playbackRate } = status;
	const isPlaying = status.isLoaded && status.isPlaying;
	const { positionMs, durationMs } = useProgress();

	const hasStartedPlayingRef = useRef(false);
	useEffect(() => {
		if (eventFields && isPlaying && !hasStartedPlayingRef.current) {
			hasStartedPlayingRef.current = true;
			dispatchIE(InteractionEvent.audio_started, eventFields);
		}
	}, [dispatchIE, eventFields, isPlaying]);

	const [isMajorityComplete, setIsMajorityComplete] = useState(false);
	const hasReportedMajorityCompleteRef = useRef(false);
	useEffect(() => {
		if (
			eventFields &&
			isMajorityComplete &&
			!hasReportedMajorityCompleteRef.current
		) {
			hasReportedMajorityCompleteRef.current = true;
			dispatchIE(InteractionEvent.audio_majority_completed, eventFields);
		}
	}, [dispatchIE, eventFields, isMajorityComplete]);

	const [isComplete, setIsComplete] = useState(false);
	useEffect(() => {
		if (eventFields && isComplete) {
			dispatchIE(InteractionEvent.audio_completed, eventFields);
		}
	}, [dispatchIE, eventFields, isComplete]);

	const progressPercent = useRef(0);
	useEffect(() => {
		progressPercent.current = Math.min(1, Math.max(0, positionMs / durationMs));
	}, [durationMs, positionMs]);

	const reportProgress = useCallback(() => {
		if (!eventFields) return;
		const progressBp = Math.round(progressPercent.current * 10000);
		if (isNaN(progressBp)) {
			return;
		}
		dispatchIE(InteractionEvent.audio_progress_update, {
			...eventFields,
			progress_bp: progressBp,
		});
	}, [dispatchIE, eventFields]);
	useScreenEvent(ScreenEvent.blur, reportProgress);
	useEffect(() => {
		if (hasStartedPlayingRef.current && !isPlaying) {
			reportProgress();
		}
	}, [isPlaying, reportProgress]);

	useEffect(() => {
		if (!eventFields || (isMajorityComplete && isComplete)) return;
		let interval: ReturnType<typeof setInterval>;
		const checkProgress = () => {
			const progressBp = Math.round(progressPercent.current * 10000);
			if (isNaN(progressBp)) {
				return;
			}
			if (isPlaying && progressPercent.current >= 0.5) {
				setIsMajorityComplete(true);
			}
			if (progressPercent.current >= 0.99) {
				setIsComplete(true);
			}
		};
		if (isPlaying) {
			checkProgress();
			interval = setInterval(checkProgress, 3000);
		}
		return () => clearInterval(interval);
	}, [isPlaying, dispatchIE, eventFields, isMajorityComplete, isComplete]);

	const positionMsRef = useRef(0);
	useEffect(() => {
		const lastUpdatePositionMs = getProgress().positionMs;
		const lastUpdateTime = Date.now();
		positionMsRef.current = lastUpdatePositionMs;

		if (!isPlaying) {
			return;
		}

		const interval = setInterval(() => {
			positionMsRef.current =
				lastUpdatePositionMs + Date.now() - lastUpdateTime;
		}, POSITION_REF_UPDATE_INTERVAL_MS);

		return () => clearInterval(interval);
	}, [getProgress, isPlaying]);

	const hasError = !!error;
	const _totalTimeSec = Math.round(durationMs / 1000);
	const totalTimeSec = isNaN(_totalTimeSec) ? 0 : _totalTimeSec;

	const currentTimeSec = Math.round(positionMs / 1000);
	const maximumValue = isNaN(totalTimeSec) ? 1 : totalTimeSec;
	const onValueChange = useCallback(
		(sec: number) => {
			setProgress({ positionMs: sec * 1000 });
		},
		[setProgress],
	);

	const playState = useRef<'playing' | 'paused'>('paused');

	const onSlidingComplete = useCallback(
		(progress: number) => {
			jumpPositionTo(progress);
			if (playState.current === 'playing') {
				play();
			}
		},
		[jumpPositionTo, play, playState],
	);

	const onSlidingStart = useCallback(() => {
		if (isPlaying) {
			pause();
		}
	}, [pause, isPlaying]);

	const onPlayControlPress = useCallback(() => {
		if (isPlaying) {
			playState.current = 'paused';
			pause();
		} else {
			playState.current = 'playing';
			play();
		}
	}, [isPlaying, pause, play]);

	const skipLoadingWaveform = !showWaveform || !audioWaveformUri;
	const waveformData = useLoadAudioWaveformData(audioWaveformUri, {
		skip: skipLoadingWaveform,
		captureError,
	});

	const isStandalonePlayer = variant === 'standalone';
	const allowTouchTrack = Platform.OS === 'web';

	const slider = (
		<Pressable>
			<Slider
				key={`slider-${maximumValue}`} // Slider misbehaves when dynamically changing max value, force new instance
				value={maximumValue <= 1 ? 0 : currentTimeSec}
				onValueChange={onValueChange} // @TODO: UX improvement, throttle audio seeking, but instantly update time indicator
				onSlidingComplete={onSlidingComplete}
				onSlidingStart={onSlidingStart}
				minimumTrackTintColor={
					isStandalonePlayer ? BrandColor.primary_yellow : theme.fill_highlight
				}
				maximumTrackTintColor={
					isStandalonePlayer ? BrandColor.neutral_600 : theme.border_neutral
				}
				thumbTintColor={
					isStandalonePlayer ? theme.content_primary : theme.fill_highlight
				}
				thumbStyle={styles.thumb}
				trackStyle={styles.track}
				allowTouchTrack={allowTouchTrack}
				disabled={!status.isLoaded}
				minimumValue={0}
				maximumValue={maximumValue}
			/>
		</Pressable>
	);

	const playControl = (
		<PressableOpacity onPress={onPlayControlPress} style={styles.playIcon}>
			<View style={isStandalonePlayer ? styles.playIconWrap : undefined}>
				{isPlaying ? (
					<PauseIcon fill={theme.content_primary} />
				) : (
					<PlayIcon fill={theme.content_primary} />
				)}
			</View>
		</PressableOpacity>
	);

	const setPlaybackRate = useCallback(
		(rate: PlaybackRateType) => {
			audioPlayer.setPlaybackRate(rate);
			setPlaybackSpeed?.(rate);
		},
		[audioPlayer, setPlaybackSpeed],
	);

	if (isStandalonePlayer) {
		return (
			<AudioPlayerView
				playbackRate={savedPlaybackSpeed ?? playbackRate}
				maximumValue={maximumValue}
				currentTimeSec={currentTimeSec}
				onValueChange={onValueChange}
				onSlidingComplete={onSlidingComplete}
				onSlidingStart={onSlidingStart}
				totalTimeSec={totalTimeSec}
				jumpPositionBy={jumpPositionBy}
				setPlaybackRate={setPlaybackRate}
				positionMsRef={positionMsRef}
				hasError={hasError}
				error={error}
				status={status}
				slider={slider}
				playControl={playControl}
				audioImage={audioImage}
				durationMs={durationMs}
				showWaveform={showWaveform}
				waveformData={waveformData}
				waveformColor={waveformColor}
			/>
		);
	}

	return (
		<AudioPlayerInline
			showWaveform={showWaveform}
			playbackRate={savedPlaybackSpeed ?? playbackRate}
			maximumValue={maximumValue}
			currentTimeSec={currentTimeSec}
			onValueChange={onValueChange}
			onSlidingComplete={onSlidingComplete}
			onSlidingStart={onSlidingStart}
			totalTimeSec={totalTimeSec}
			setPlaybackRate={setPlaybackRate}
			positionMsRef={positionMsRef}
			durationMs={durationMs}
			hasError={hasError}
			error={error}
			status={status}
			slider={slider}
			playControl={playControl}
		/>
	);
};

const BUTTON_HITSLOP = 10;

const stylesheet = createStyleSheet(({ spacing }) => ({
	root: {
		position: 'relative',
	},
	graphic: {
		alignItems: 'center',
		paddingVertical: spacing.xl3,
	},
	player: {
		height: 52,
		borderRadius: 52,
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
		paddingHorizontal: spacing.lg,
	},
	control: {
		paddingHorizontal: 6,
	},
	timeControl: {
		minWidth: 60,
		textAlign: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	sliderControl: {
		flexGrow: 1,
	},
	playIconWrap: {
		width: 24,
		height: 24,
	},
	playIcon: {
		variants: {
			variant: {
				standalone: {
					width: 68,
					height: 68,
					backgroundColor: `${BrandColor.neutral_600}4D`,
					borderRadius: 34,
					alignItems: 'center',
					justifyContent: 'center',
				},
				inline: {
					width: Spacing.dsDeviation__10 + BUTTON_HITSLOP * 2,
					padding: BUTTON_HITSLOP,
					margin: -BUTTON_HITSLOP,
				},
			},
		},
	},
	thumb: {
		variants: {
			variant: {
				standalone: {
					width: spacing.sm,
					height: spacing.sm,
				},
				inline: {
					width: spacing.md,
					height: spacing.md,
				},
			},
		},
	},
	track: {
		borderRadius: 5,
		variants: {
			variant: {
				standalone: {
					height: 2,
				},
				inline: {
					height: 5,
				},
			},
		},
	},
	metaControls: {
		paddingHorizontal: spacing.xl3,
		marginTop: spacing.xs,
	},
}));

export default AudioPlayerContainer;
