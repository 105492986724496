import { Spacing } from '@wearemojo/ui-constants';
import { ActivityIndicator, View } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import BackgroundView from './BackgroundView';
import ErrorBox from './ErrorBox';
import useUITheme from './hooks/useUITheme';
import WaveformIcon from './icons/WaveformIcon';
import Stack from './Stack';
import Text from './Text';
import {
	AudioPlayerChildrenProps,
	formatTime,
	PLAYBACK_RATES,
} from './utils/audio';

const AudioPlayerInline = ({
	playbackRate,
	slider,
	currentTimeSec,
	totalTimeSec,
	setPlaybackRate,
	hasError,
	error,
	status,
	playControl,
	showWaveform,
}: AudioPlayerChildrenProps) => {
	const theme = useUITheme();
	const { styles } = useStyles(stylesheet);
	return (
		<View style={styles.root}>
			{showWaveform && (
				<View style={styles.graphic}>
					<WaveformIcon />
				</View>
			)}
			<BackgroundView background="background_secondary" style={styles.player}>
				<View style={styles.control}>
					{!status.isLoading ? (
						playControl
					) : (
						<ActivityIndicator size="small" color={theme.content_primary} />
					)}
				</View>
				<View style={[styles.control, styles.timeControl]}>
					<Text>{formatTime(currentTimeSec)}</Text>
				</View>
				<View style={[styles.control, styles.sliderControl]}>{slider}</View>
				<Text>{formatTime(totalTimeSec)}</Text>
			</BackgroundView>
			<View style={styles.metaControls}>
				<Stack direction="row" spacing={Spacing.small}>
					<Text weight="bold" variant="label_sm" themeColor="content_neutral">
						Playback Speed:
					</Text>
					{PLAYBACK_RATES.map((rate) => (
						<Text
							key={rate}
							themeColor={
								rate === playbackRate ? 'content_primary' : 'content_neutral'
							}
							underline={rate === playbackRate}
							variant="label_sm"
							onPress={() => setPlaybackRate(rate)}
						>
							{rate}x
						</Text>
					))}
				</Stack>
			</View>
			{hasError && (
				<ErrorBox label="Audio Error" message={String(error)} fillParent />
			)}
		</View>
	);
};

const stylesheet = createStyleSheet(({ spacing }) => ({
	root: {
		position: 'relative',
	},
	graphic: {
		alignItems: 'center',
		paddingVertical: spacing.xl3,
	},
	player: {
		height: 52,
		borderRadius: 52,
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'row',
		paddingHorizontal: spacing.lg,
	},
	control: {
		paddingHorizontal: spacing.xs,
	},
	timeControl: {
		minWidth: 60,
		textAlign: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	sliderControl: {
		flexGrow: 1,
	},
	metaControls: {
		paddingHorizontal: spacing.xl3,
		marginTop: spacing.xs,
	},
}));

export default AudioPlayerInline;
