import { borderRadius, themeColors } from '@wearemojo/ui-constants';
import { useCallback } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import useUIContext from './hooks/useUIContext';
import TickIcon from './icons/TickIcon';

export type Props = {
	checked?: boolean;
	disabled?: boolean;
	onChange?: (checked: boolean) => void;
	variant?: 'circle' | 'square';
};

const Checkbox = ({
	checked = false,
	disabled = false,
	onChange,
	variant = 'circle',
}: Props) => {
	const { theme } = useUIContext();
	const onPress = useCallback(() => {
		onChange?.(!checked);
	}, [checked, onChange]);
	const isSquare = variant === 'square';

	return (
		<Pressable
			testID="checkbox"
			style={styles.root}
			onPress={onPress}
			disabled={disabled}
			accessibilityLabel="checkbox"
			accessibilityRole="checkbox"
			accessibilityState={{
				checked,
				disabled,
			}}
		>
			<View style={[styles.container, isSquare && styles.squareCheckbox]}>
				<View
					style={[
						styles.outline,
						checked && { borderColor: themeColors[theme].border_highlight },
						isSquare && styles.squareCheckbox,
					]}
				>
					<View
						style={[
							styles.box,
							{ borderColor: themeColors[theme].border_primary },
							disabled && styles.boxDisabled,
							isSquare && styles.boxSquareCheckbox,
						]}
					>
						{checked && <TickIcon />}
					</View>
				</View>
			</View>
		</Pressable>
	);
};

export const CHECKBOX_SIZE = 20;
export const CHECKBOX_BORDER_WIDTH = 2;

const HITSLOP = 4;

const styles = StyleSheet.create({
	root: {
		margin: -HITSLOP,
		padding: HITSLOP,
	},
	container: {
		flexGrow: 0,
		width: CHECKBOX_SIZE + CHECKBOX_BORDER_WIDTH * 2,
		height: CHECKBOX_SIZE + CHECKBOX_BORDER_WIDTH * 2,
		borderRadius, // For browser focus outline
	},
	outline: {
		borderRadius,
		borderWidth: CHECKBOX_BORDER_WIDTH,
		borderColor: 'transparent',
	},
	box: {
		width: CHECKBOX_SIZE,
		height: CHECKBOX_SIZE,
		borderRadius: borderRadius - CHECKBOX_BORDER_WIDTH,
		borderWidth: CHECKBOX_BORDER_WIDTH,
		alignItems: 'center',
		justifyContent: 'center',
		padding: 2,
	},
	boxDisabled: {
		opacity: 0.5,
	},
	squareCheckbox: {
		// Looks odd with either BorderRadius.extraSmall or BorderRadius.small 🫠
		borderRadius: 6,
	},
	boxSquareCheckbox: {
		borderRadius: 6 - CHECKBOX_BORDER_WIDTH,
	},
});

export default Checkbox;
