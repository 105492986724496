import { borderRadius, Spacing, themeColors } from '@wearemojo/ui-constants';
import {
	Children,
	cloneElement,
	isValidElement,
	ReactNode,
	useState,
} from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import ChevronDownIcon from 'react-native-heroicons/outline/ChevronDownIcon';
import ChevronUpIcon from 'react-native-heroicons/outline/ChevronUpIcon';

import BackgroundView from './BackgroundView';
import useUIContext from './hooks/useUIContext';
import useUITheme from './hooks/useUITheme';
import Text from './Text';

type Props = {
	initialExpanded?: number | Array<number>;
	toggleMode?: 'single' | 'multiple';
	children: ReactNode;
	compact?: boolean;
};

type ItemProps = {
	title: string;
	children: ReactNode;
	_expanded?: boolean;
	_compact?: boolean;
	_onToggle?: () => void;
};

const Accordion = ({
	initialExpanded = [],
	toggleMode = 'single',
	compact: _compact = false,
	children,
}: Props) => {
	const [expandedIndices, setExpandedIndices] = useState(
		Array.isArray(initialExpanded) ? initialExpanded : [initialExpanded],
	);
	const items = Children.map(children, (child, index) => {
		if (!isValidElement(child) || child.type !== Accordion.Item) {
			console.warn(
				'Invalid child passed to Accordion, expects type Accordion.Item only',
				child,
			);
			return null;
		}
		const _expanded = expandedIndices.includes(index);
		const _onToggle = () =>
			toggleMode === 'single'
				? setExpandedIndices(_expanded ? [] : [index])
				: setExpandedIndices(toggle(expandedIndices, index));
		return cloneElement(child, {
			_expanded,
			_compact,
			_onToggle,
		} as Partial<ItemProps>);
	});
	return (
		<BackgroundView background="background_secondary" style={styles.root}>
			{items}
		</BackgroundView>
	);
};

const AccordionItem = ({
	title,
	children,
	_expanded = false,
	_compact = false,
	_onToggle,
}: ItemProps) => {
	const { theme } = useUIContext();
	const stroke = useUITheme().content_primary;
	const Icon = _expanded ? ChevronUpIcon : ChevronDownIcon;
	return (
		<View>
			<Pressable onPress={_onToggle} style={styles.title}>
				<Text
					variant={_compact ? 'body_md' : 'body_md'}
					weight="bold"
					style={styles.titleText}
				>
					{title}
				</Text>
				<Icon stroke={stroke} size={24} style={styles.icon} />
			</Pressable>
			{_expanded && (
				<View
					style={[
						styles.content,
						_compact && styles.contentCompact,
						{ borderTopColor: themeColors[theme].border_primary },
					]}
				>
					{children}
				</View>
			)}
		</View>
	);
};

Accordion.Item = AccordionItem;

function toggle<T>(array: Array<T>, value: T) {
	const index = array.indexOf(value);

	if (index === -1) {
		return [...array, value];
	}
	return [...array.slice(0, index), ...array.slice(index + 1)];
}

const styles = StyleSheet.create({
	root: {
		borderRadius,
	},
	title: {
		padding: Spacing.large,
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	titleText: { flex: 1 },
	content: {
		borderTopWidth: 1,
		marginHorizontal: Spacing.large,
		paddingVertical: Spacing.large,
	},
	contentCompact: {
		borderTopWidth: 0,
		paddingTop: 0,
	},
	icon: {},
});

export default Accordion;
