import { StyleProp, StyleSheet, ViewStyle } from 'react-native';

import BackgroundView from './BackgroundView';

type Props = {
	style?: StyleProp<ViewStyle>;
};

const ActivityDot = ({ style }: Props) => {
	return (
		<BackgroundView background="fill_warning" style={[styles.root, style]} />
	);
};

export const ACTIVITY_DOT_SIZE = 8;

const styles = StyleSheet.create({
	root: {
		borderRadius: ACTIVITY_DOT_SIZE,
		width: ACTIVITY_DOT_SIZE,
		height: ACTIVITY_DOT_SIZE,
	},
});

export default ActivityDot;
