import { StyleProp, View, ViewStyle } from 'react-native';
import { createStyleSheet, useStyles } from 'react-native-unistyles';

import Text from './Text';

const Caption = ({
	title,
	style,
}: {
	title: string;
	style?: StyleProp<ViewStyle>;
}) => {
	const { styles } = useStyles(stylesheet);
	return (
		<View style={[styles.caption, style]}>
			<Text variant="label_sm">{title}</Text>
		</View>
	);
};

const stylesheet = createStyleSheet(({ spacing, radius, colors }) => ({
	caption: {
		alignSelf: 'flex-start',
		borderRadius: radius.sm,
		paddingVertical: spacing.xs2,
		paddingHorizontal: spacing.xs,
		backgroundColor: `${colors.background_primary}80`,
	},
}));

export default Caption;
