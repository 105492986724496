import { Spacing } from '@wearemojo/ui-constants';
import LottieView, { LottieViewProps } from 'lottie-react-native';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import BackgroundView from './BackgroundView';
import PauseIcon from './icons/PauseIcon';
import PlayIcon from './icons/PlayIcon';
import LoadingIndicator from './LoadingIndicator';
import { AudioPlayerEvent, AudioPlayerInstance } from './utils/audio';
import {
	AnimationInteractionEvent,
	InteractionEvent,
	useInteractionEvents,
} from './utils/interactionEvents';
import { ScreenEvent, useScreenEvent } from './utils/screenEvents';
import { PressableCallback } from './utils/types';

type Props = {
	source: LottieViewProps['source'];
	autoPlay?: boolean;
	loop?: boolean;
	audioPlayer?: AudioPlayerInstance;
	eventFields: AnimationInteractionEvent;
};

const Animation = ({
	source,
	autoPlay = false,
	loop = false,
	audioPlayer,
	eventFields,
}: Props) => {
	const isUriSource = typeof source === 'object' && 'uri' in source;
	const hasAudio = !!audioPlayer;
	const { dispatch } = useInteractionEvents();
	const [isPlaying, setIsPlaying] = useState(autoPlay);
	const [sourceData, setSourceData] = useState<
		LottieViewProps['source'] | undefined
	>();
	const animationRef = React.createRef<LottieView>();

	useEffect(() => {
		if (isUriSource) {
			const { uri } = source;
			const fetchData = async () => {
				const response = await fetch(uri);
				const data = await response.json();
				setSourceData(data);
			};
			fetchData();
		}
	}, [isUriSource, source]);

	const isPlayingRef = useRef(false);
	useEffect(() => {
		if (isPlaying && !isPlayingRef.current) {
			isPlayingRef.current = true;
			dispatch(InteractionEvent.animation_started, eventFields);
		}
	}, [dispatch, eventFields, isPlaying]);

	const onAnimationFinish = useCallback(() => {
		dispatch(InteractionEvent.animation_completed, eventFields);
	}, [dispatch, eventFields]);

	const start = useCallback(() => {
		animationRef.current?.play();
		if (hasAudio) {
			audioPlayer.setProgress({ positionMs: 0 });
			audioPlayer.play();
		}
	}, [animationRef, audioPlayer, hasAudio]);

	const stop = useCallback(() => {
		animationRef.current?.reset();
		if (hasAudio) {
			audioPlayer.stop();
		}
	}, [animationRef, audioPlayer, hasAudio]);

	useScreenEvent(ScreenEvent.navigateOut, () => {
		stop();
	});

	const onTogglePlay = useCallback(() => {
		isPlaying ? stop() : start();
		setIsPlaying(!isPlaying);
	}, [isPlaying, stop, start]);
	useScreenEvent(ScreenEvent.blur, stop);

	const [isAudioLoaded, setIsAudioLoaded] = useState(false);
	useEffect(() => {
		audioPlayer?.on(AudioPlayerEvent.status, (status) => {
			if (hasAudio && status.isLoaded) {
				setIsAudioLoaded(true);
			}
		});
	}, [audioPlayer, hasAudio]);

	const data = isUriSource ? sourceData : source;
	if (!data) {
		return null;
	}

	const showLoading = hasAudio && !isAudioLoaded;
	const showPlayButton = hasAudio ? isAudioLoaded : !autoPlay;

	const aspectRatioStyle = data
		? { aspectRatio: (data as any).w / (data as any).h }
		: undefined;

	return (
		<View>
			<LottieView
				ref={animationRef}
				source={data}
				autoPlay={autoPlay}
				loop={loop}
				onAnimationFinish={onAnimationFinish}
				style={[aspectRatioStyle, styles.animation]}
				resizeMode="contain"
			/>
			{showLoading && (
				<View style={StyleSheet.absoluteFill}>
					<LoadingIndicator label="Animation" />
				</View>
			)}
			{showPlayButton && (
				<PlayButton isPlaying={isPlaying} onTogglePlay={onTogglePlay} />
			)}
		</View>
	);
};

const PlayButton = ({
	isPlaying,
	onTogglePlay,
}: {
	isPlaying: boolean;
	onTogglePlay: () => void;
}) => {
	const fill = '#fff';
	return (
		<Pressable onPress={onTogglePlay} style={StyleSheet.absoluteFill}>
			{(state) => {
				const { hovered } = state as PressableCallback;
				return (
					<View style={styles.playContainer}>
						{(!isPlaying || hovered) && (
							<BackgroundView
								background="background_modal"
								style={styles.playIcon}
							>
								{isPlaying ? (
									<PauseIcon fill={fill} />
								) : (
									<PlayIcon fill={fill} />
								)}
							</BackgroundView>
						)}
					</View>
				);
			}}
		</Pressable>
	);
};

const PLAY_BUTTON_SIZE = 100;

const styles = StyleSheet.create({
	animation: {
		width: '100%',
		position: 'relative',
	},
	poster: {
		...StyleSheet.absoluteFillObject,
		backgroundColor: '#000',
	},
	playContainer: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		height: PLAY_BUTTON_SIZE,
		width: PLAY_BUTTON_SIZE,
	},
	playIcon: {
		top: '-50%',
		left: '-50%',
		height: PLAY_BUTTON_SIZE,
		width: PLAY_BUTTON_SIZE,
		padding: Spacing.extraLarge,
		borderRadius: 9999,
	},
});

export default Animation;
