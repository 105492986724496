import { ComponentType } from 'react';

import PopupInfo from './PopupInfo';
import { BlockContent, MojoIcon } from './utils/types';
import AbstractModalView from './views/AbstractModalView';

type Props = {
	icon?: ComponentType<MojoIcon>;
	fragment: {
		title: string;
		content: BlockContent;
		ctaText: string;
		secondaryCtaText?: string;
		textActionCta?: string;
	};
	onCtaPress: () => void;
	onSecondaryCtaPress?: () => void;
	onTextActionCtaPress?: () => void;
	onClosePress?: () => void;
};

const AbstractModal = ({
	icon,
	fragment,
	onCtaPress,
	onSecondaryCtaPress,
	onTextActionCtaPress,
	onClosePress,
}: Props) => {
	return (
		<PopupInfo onClosePress={onClosePress}>
			<AbstractModalView
				{...fragment}
				icon={icon}
				onCtaPress={onCtaPress}
				onSecondaryCtaPress={onSecondaryCtaPress}
				onTextActionCtaPress={onTextActionCtaPress}
			/>
		</PopupInfo>
	);
};

export default AbstractModal;
