import { TextProps } from 'react-native';

import CMSContent from './CMSContent';
import { default as Text, TextContextProvider, TextPropStyles } from './Text';
import { withErrorBoundary } from './utils/errors';
import { ContentVariables, InlineContent } from './utils/types';

type Props = {
	value: InlineContent;
	contentVariables?: ContentVariables;
} & TextPropStyles &
	TextProps;

// Note we externally expose this as <Text.CMSContent ... /> for convenience.
// It should always return a <Text> component, and be acceptable as a child of
// another <Text> component.
const CMSInlineContent = ({ value, contentVariables, ...textProps }: Props) => {
	const content =
		typeof value === 'string' ? (
			<Text>{value}</Text>
		) : (
			<CMSContent
				value={Array.isArray(value) ? value : value.content}
				contentVariables={contentVariables}
				normalTextVariant={textProps.variant}
			/>
		);

	return <TextContextProvider {...textProps}>{content}</TextContextProvider>;
};

export default withErrorBoundary(CMSInlineContent);
